<template>
  <div class="d-flex flex-column  flex-grow-1">
    <document-toolbar
      :tab="tab"
      :save="save"
      close-after-save
      v-on="$listeners"
      no-history
    >
    </document-toolbar>
    <document-in-tab>
      <v-form
        v-model="form"
        ref="form"
      >
        <comment
          :text.sync="computedComment"
          :showComment="true"
          :disabled="!documentIsNew"
        />
      </v-form>
    </document-in-tab>
  </div>
</template>

<script>
import DocumentEditor from '@/mixins/document-editor'

/* TODO REMOVE CACHE (il ne sert à rien dans ce cas on ne fait que créer des nouveaux documents sans les charger dans cette page) */
export default {
  mixins: [DocumentEditor],
  components: {
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Comment: () => import('@/components/comment')
  },
  data () {
    return {
    }
  },
  async created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  computed: {
    cacheType () {
      return DocumentEditor.CacheType.WorkItemRef
    },
    id () {
      return this.tab.documentId
    },
    computedComment: {
      get () {
        return this.document?.closingComment
      },
      set (v) {
        this.document.closingComment = v
      }
    },
    form: {
      get () {
        return false
      },
      set (v) {
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(this.documentIsNew && v) })
      }
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        this.document = Object.assign({}, this.document, {
          workItemTypeId: this.tab.typeId,
          closingComment: null,
          accountId: this.$route.params.id
        })
        this.$emit('document-title-change', {
          tab: this.tab, value: `${this.tab.typeName}`
        })
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }
        this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.task.number}` })
        if ((this.document.closingComment || '').length) {
          this.$emit('document-comment', { tab: this.tab, value: true })
        }
      }

      this.validateForm()
    },
    validateForm () {
      this.$waitFor(() => this.$refs.form)
        .then(form => this.$emit('document-can-save', { tab: this.tab, value: Boolean(form.validate()) }))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    workItemToJSON () {
      // Remap participants
      return {
        accountId: this.document.accountId,
        closingComment: this.document.closingComment,
        workItemTypeId: this.document.workItemTypeId
      }
    },
    async save () {
      if (this.documentIsNew) {
        return this.$http().post('/core/v6/generic-task', this.workItemToJSON())
      }
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  props: {
    tab: Object
  }
}
</script>
